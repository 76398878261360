import * as React from "react"
import {graphql, useStaticQuery} from "gatsby";
import InnerLayout from "./../../components/global/innerLayout"
import parse from "html-react-parser";
import PageTitle from "../../components/global/pageTitle";

const Page = () => {

    const seoMatic = {
        metaTitleContainer: "<title>Thank you for updating your preferences | Chicken Soup for the Soul</title>"
    }

    return (
        <InnerLayout seomatic={seoMatic}>
            <div className="midd">
                <PageTitle title={`Thank you for updating your preferences`} />

                <div className="content-page">
                    <div className="container-small">
                        <div className="content-colum-row">
                            <div className="content">
                                <p>You have successfully updated your subscription preferences. Catch up on our most <a href="https://www.chickensoup.com/stories">recent free stories</a> now.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </InnerLayout>
    )
}

export default Page
